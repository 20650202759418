<template>
  <div class="NoLoginBar"
       v-if="!isLogin">
    <no-ssr>
      <div class="mask" v-show="isShowMask" :class="{ 'ON': isShowNoLoginBar }">
        <div class="login-box">
          <span class="btn-login _pointer" @click="login(1)">登录</span>
          <span class="desc-login">可查看更多优质内容哦~</span>
        </div>
      </div>
      <div class="bar" @click.stop="login(1)">
        <div class="inner_center">

          <div class="layout-left">
            <i class="iconfont icon-logo icon-logo1"></i>
            <span>首次登录送<i class="color-green">10天</i>免费下载，超高清图片随心下载</span>
          </div>
          <div class="layout-right">

            <i class="iconfont icon-mobile" @click="login(0)"/>
            <i class="iconfont icon-QQ2" @click="loginByQQ(2)"/>

            <div class="wx-login" @click="login(1)">
              <i class="iconfont icon-wechat2"/>
              微信扫码登录
            </div>

          </div>

        </div>
      </div>
    </no-ssr>
  </div>
</template>

<script>
import {fnLogin} from "@/fn";
import {mapGetters, mapState} from "vuex";

export default {
  name: "NoLoginBar",
  computed: {
    ...mapGetters('user', ['isLogin']),
  },
  props: {
    isShowMask: {
      type: Boolean,
      default: true
    },
    isShowLoginBar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isShowNoLoginBar: false
    }
  },
  async mounted() {
    if (!this.isShowLoginBar) {
      this.isShowNoLoginBar = true;
      return
    }
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    login(index) {
      this.$store.commit('user/SET_LOGIN_WAY', index)
      fnLogin.loginIntercept(false);
    },
    loginByQQ() {
      fnLogin.clearListenLoginByQQ();
      this.login(0)
      setTimeout(() => {
        fnLogin.loginByQQ(this.$store);
      }, 300)
    },
    handleScroll() {
      if (!this.isShowLoginBar) {
        return
      }
      // this.visible = window.pageYOffset > this.visibilityHeight
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      /*if (scrollTop + windowHeight == scrollHeight) {
        //写后台加载数据的函数
        console.log("距顶部" + scrollTop + "可视区高度" + windowHeight + "滚动条总高度" + scrollHeight);
      }*/

      // console.log("距顶部" + scrollTop + "可视区高度" + windowHeight + "滚动条总高度" + scrollHeight);
      if (scrollTop + windowHeight >= scrollHeight - 600) {
        //写后台加载数据的函数
        // console.log("距顶部" + scrollTop + "可视区高度" + windowHeight + "滚动条总高度" + scrollHeight);
        this.isShowNoLoginBar = true
      } else {
        this.isShowNoLoginBar = false
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.color-green {
  color: rgba(98, 251, 154, 1);
}

.NoLoginBar {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
  transition: all 0.5s ease;

  &.ON {
    bottom: 0;
  }

  .mask {
    width: 100%;
    height: 422px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 19%, rgba(255, 255, 255, 0.8) 53%, #FFFFFF 100%);
    //position: relative;
    position: fixed;
    bottom: -999px;
    transition: all 0.5s ease;
    z-index: 5;

    &.ON {
      bottom: 80px !important;
    }


    .login-box {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;

      .btn-login {
        display: inline-block;
        width: 56px;
        height: 30px;
        background: #F5F6FA;
        border-radius: 15px;
        font-size: 14px;
        font-weight: bold;
        color: #424866;
        text-align: center;
        line-height: 30px;
        margin-right: 8px;
      }

      .desc-login {
        font-size: 14px;
        color: #424866;
        line-height: 20px;
      }
    }
  }

  .bar {
    width: 100%;
    height: 80px;
    background: linear-gradient(180deg, #9373FF 0%, #6969FF 100%);
    position: relative;
    z-index: 6;

    .inner_center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      .layout-left {
        @include LayoutFlex(1);

        span {
          font-size: 18px;
          font-weight: bold;
          color: #FFE48A;
          line-height: 27px;
          letter-spacing: 2px;
          margin-left: 38px;
          position: relative;

          &:after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 16px;
            background: #FFFFFF;
            border-radius: 1px;
            color: rgba(255, 255, 255, 1);
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
          }

          .color-green {
            letter-spacing: 2px;
          }
        }
      }

      .layout-right {
        @include LayoutFlex(1);

        .icon-mobile, .icon-QQ2 {
          width: 32px;
          height: 32px;
          background: #FFFFFF;
          border-radius: 50%;
          @include LayoutFlex(1);
          font-size: 18px;
          cursor: pointer;
        }

        .icon-mobile {
          color: rgba(89, 96, 128, 1);

          &:hover {
            background: #596080 !important;
            color: #FFFFFF;
          }
        }

        .icon-QQ2 {
          color: rgba(6, 180, 253, 1);
          margin: 0 12px;

          &:hover {
            background: rgba(6, 180, 253, 1) !important;
            color: #FFFFFF;
          }
        }

        .wx-login {
          width: 136px;
          height: 32px;
          background: linear-gradient(145deg, #00C68A 0%, #00D292 100%);
          border-radius: 18px;
          @include LayoutFlex(1);
          cursor: pointer;

          font-size: 13px;
          color: #FFFFFF;
          line-height: 20px;
          letter-spacing: 1px;

          &:hover {
            background: linear-gradient(145deg, #00D292 0%, #00C68A 100%);
          }

          .icon-wechat2 {
            color: #FFFFFF;
            font-size: 18px;
            margin-right: 6px;
          }
        }
      }


      .icon-logo1 {
        color: #FFFFFF;
        font-size: 24px;
        background-color: transparent;
      }
    }
  }

}
</style>
