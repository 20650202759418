<template>
  <div class="inner_center" v-if="mapIndex > -1">
    <div class="HomeMapWrap" v-if="mapIndex > -1">
      <div class="map-item-bar" :style="{ 'top': mapIndex * (compare1400 ? 40 : 44 ) + 8 +  'px' }"></div>

      <div class="map-item"
           v-for="(vo,idx) in list"
           :key="idx"
           @click="toggleMap(vo,idx)"
           :class="{ 'ON' : idx == mapIndex }"
      >
         <div class="map-item-label">
           {{ vo.label }}
         </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "HomeMap",
  computed:{
  },
  data(){
    return{
    }
  },
  props:{
    list:{
      type: Array,
      default: ()=>[]
    },
    mapIndex:{
      type: String | Number,
      default: ''
    },
    compare1400:{
      type: Boolean,
      default: true
    }
  },
  destroyed() {
  },
  mounted() {


  },
  methods:{

    toggleMap(vo,idx){
      this.$emit('toggleMap',idx)
    }
  }
}
</script>

<style lang="scss" scoped>
.inner_center{
  position: fixed;
  margin: 0 auto;
  right: 0;
  left: 0;
  bottom: 0;
  height: 0;
  //background: pink;
  z-index: 10;
}
.HomeMapWrap{
  display: inline-block;
  z-index: 88;
  position: absolute;
  left: 0px;
  bottom: 150px;
  //transform: translateY(-50%);
  margin-left: -120px;

  width: 86px;
  height: 179px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(10,37,64,0.1);
  border-radius: 8px;
  padding: 8px 0;
  box-sizing: border-box;

  .map-item{
    height: 40px;
    line-height: 40px;
    text-align: center;
    //background: #FFFFFF;
    border-radius: 2px;
    font-size: 13px;
    color: #424866;
    letter-spacing: 1px;
    position: relative;
    cursor: pointer;

    &:last-child{
      &:after{
        display: none;
      }
    }
    &.ON{
      color: $color-primary;
    }

    &:after{
      content: '';
      width: 100%;
      height: 1px;
      display: inline-block;
      background: linear-gradient(90deg, #FFFFFF 0%, #EBEDF5 49%, #FFFFFF 100%);
      border-radius: 1px;
      @include Position(1,4,0,0);
    }
  }

  .map-item-bar{
    @include BgImage(98px, 45px, 'hom/map/map-bg%40large.png');
    @include Position(1,5,50%,8px);
    transform: translateX(-50%);
    transition: all 0.5s ease;
  }

  @include Screen(1){
    //background: pink;
    margin-left: -74px;
    height: 192px;
    width: 54px;

    .map-item-bar{
      @include BgImage(66px, 49px, 'hom/map/map-bg%40small.png');
    }

    .map-item{
      width: 54px;
      height: 44px;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      align-items: center;
      justify-content: center;

      .map-item-label{
        width: 30px;
        line-height: 15px;
        text-align: center;
      }
    }
  }
}
</style>
