<template>
  <div class="BannerNav inner_center">
    <div
      class="BannerNavCalendar inner_center banner-list"
    >
      <a
        :href="vo.link_url"
        v-for="vo in bannerData.slice(0,3)"
        :key="vo.id"
        class="img-wrap _pointer img-wrap"
        @click.prevent="listenBannerClick(vo),navigatorTo(vo.link_url)"
      >
        <img
          :src=" 'https://pic.chaopx.com'+ vo.pic_url  + '!/progressive/true' "
          class="swiper-img" :alt="vo.title">
      </a>
      <div class="img-wrap calendar-card">
        <a
          :href="vo.link_url"
          v-for="vo in calendarList.slice(0,4)"
          :key="vo.title +'card-item'"
          class="calendar-card-item"
          @click.prevent="navigatorTo(vo.link_url);toggleCalendar(vo)"
        >
          <div class="card-lft">
            <div class="calendar-name">{{ vo.title }}</div>
            <div class="calendar-date">{{ vo | calendarDate }}</div>
          </div>
          <div class="card-rgt">
            <!-- 当天 -->
            <div class="today" v-if="vo.days == 0">今天</div>

            <!-- 几天后 -->
            <div class="tomorrow" v-else>
              <div class="txt-day">{{ vo.days }}</div>
              <div class="txt-days"></div>
            </div>
          </div>


        </a>


        <div class="custom-schedule-border"></div>
        <!--  自定义日程 -->
        <a class="custom-schedule"
           :href="CALENDAR"
           target="_blank"
        >
          <i class="icon_copyright"></i>
          营销日历·VIP可自定义日程
          <i class="icon_circle">
          </i>
        </a>
      </div>
    </div>

    <ul
      :class="{ 'ON':isShowMenuNav}"
      class="menu-nav-list inner_center"
      ref="menuNavList">
      <a
        :href="vo.link_url"
        v-for="(vo,index) in menuNavList"
        :key="vo.id"
        :class="['menu-nav-item', vo.className ,{ 'on': menuNavIndex === index }]"
        :style="{color: vo.color}"
        @mouseover=" menuNavIndex = index"
        @mouseleave="menuNavIndex = -1"
        ref="menuNavItem"
        @click.prevent="navigatorTo(vo.link_url,vo);toggleMenu(vo)"
      >
        <div class="_pointer"
        >{{ vo.title }}
        </div>
      </a>
    </ul>
  </div>
</template>

<script>
import {fnBrowser, fnFlowStatistics} from "@/fn";
import {PROMOTION_POSITION} from "@/fn/FnFlowStatistics";
import {IMG_BASE_URL} from "@/constant/PATH";
import _ from 'lodash'
import {CALENDAR} from "@/constant/_Public/Path";

const VIEW_TYPE = {
  banner: 'banner',
  calendar: 'calendar',
}
export default {
  name: "BannerNav",
  props: {
    bannerData: {
      type: Array,
      default: () => []
    },
    menuNavList: {
      type: Array,
      default: () => []
    },
    calendarList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // menuNavList,      // 菜单列表
      menuNavIndex: -1,  // 菜单导航默认选中
      IMG_BASE_URL,
      viewType: VIEW_TYPE.banner,
      VIEW_TYPE,
      menuNavList2: [],
      isShowMenuNav: true,
      calendarListLength: 0,
      timer: null,
      CALENDAR
    }
  },
  mounted() {
    // this.initViewWidth();
    // this.toggleTimer();


  },
  methods: {
    initViewWidth() {
      this.viewWidth = document.body.clientWidth > 1440 ? 1440 : 1200;
      this.calendarListLength = this.viewWidth >= 1440 ? 3 : 2
    },

    listenBannerClick(vo) {
      fnFlowStatistics.promotion(PROMOTION_POSITION.BANNER, vo.id)
    },

    navigatorTo(path) {
      fnBrowser.navTo(path)
    },

    toggleMenu(vo) {
      fnFlowStatistics.promotion(PROMOTION_POSITION.RECOMMEND, vo.id)
    },
    toggleCalendar(vo) {
      fnFlowStatistics.promotion(PROMOTION_POSITION.CALENDAR, vo.id)
    },


    toggleTimer(flag = true) {
      if (flag) {
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          this.viewType = this.viewType === VIEW_TYPE.banner ? VIEW_TYPE.calendar : VIEW_TYPE.banner;
        }, 6000)

      } else {
        clearInterval(this.timer)
      }
    }
  },
  filters: {
    calendarDate(vo) {

      let {year, month, day, week} = vo

      return `${year}.${month}.${day} ${week}`
    }
  }
}
</script>

<style lang="scss" scoped>

.BannerNav {
  //height: 249px;
  //overflow: hidden;
  //margin-bottom: 40px;


  .banner-list {
    display: flex;
    height: 100%;
    height: 320px;
    position: relative;
    align-items: center;
    justify-content: center;


    .img-wrap {
      display: inline-block;
      //height: 162px;
      border-radius: 8px;
      margin-right: 12px;
      position: relative;
      overflow: hidden;
      display: flex;
      width: 340px;
      height: 320px;
      position: relative;

      &:after {
        display: inline-block;
        content: '';
        width: 100%;
        height: 100%;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease-in-out;
      }

      @include ElementHover('', '') {
        img {
          transform: scale(1.05);
        }
      }
    }

    .calendar-card {
      background: #FFFFFF;
      box-shadow: 0px 0px 8px 0px rgba(10, 37, 64, 0.1000);
      border-radius: 8px 32px 8px 8px;
      padding: 10px 12px;
      box-sizing: border-box;
      position: relative;
      overflow: visible;
      display: inline-block;
      width: 320px;
      height: 300px;

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(194, 99, 240, 0) 0%, rgba(194, 99, 240, 0) 66%, rgba(194, 99, 240, 0.05) 82%, rgba(194, 99, 240, 0.2) 100%);
        border-radius: 8px;
      }

      .calendar-card-item {
        display: flex;
        //width: 232px;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        border-radius: 8px;
        @include ElementHover('', linear-gradient(90deg, #EBEDF5 0%, #F5F6FA 17%, #FBFBFD 100%));
        height: 56px;
        padding: 0 18px 0 16px;
        position: relative;
        z-index: 1;


        .card-lft {
          .calendar-name {
            font-size: 14px;
            font-weight: bold;
            color: #2D2B33;
            line-height: 17px;
            letter-spacing: 1px;
            margin-bottom: 3px;
          }

          .calendar-date {
            font-size: 12px;
            color: #424866;
            line-height: 16px;
          }
        }

        .card-rgt {
          .today {
            width: 45px;
            height: 38px;
            background: rgba(245, 50, 49, 0.0800);
            border-radius: 6px;
            font-size: 14px;
            font-weight: bold;
            color: #F53231;
            line-height: 38px;
            text-align: center;
            @include BgImage(45px, 38px, 'home/path/calendar-red.png');
            line-height: 42px;
          }

          .tomorrow {
            width: 45px;
            height: 38px;
            //background: rgba(105, 105, 255, 0.0800);
            @include BgImage(45px, 38px, 'home/path/calendar-purple.png');
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;

            .txt-day {
              font-size: 18px;
              font-weight: bold;
              color: #6969FF;
              line-height: 38px;
              margin-right: 3px;
              margin-top: 3px;
            }

            .txt-days {
              @include BgImage(8px, 16px, 'home/banner/icon_tianhou.svg');
              //margin-left: 4px;
              margin-top: 5px;
            }
          }
        }


      }

      .custom-schedule-border {
        display: inline-block;
        width: 262px;
        height: 1px;
        background: #EBEDF5;
        border-radius: 1px;
        padding: 0 18px 0 16px;
        box-sizing: border-box;
        margin: 10px 16px 6px;

        @include Screen(1) {
          width: 236px;
          margin: 8px 12px 4px;
          padding: 0;

        }
      }

      .custom-schedule {
        width: 296px;
        height: 36px;
        border-radius: 8px;
        margin-right: 13px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 0 0 16px;
        font-size: 13px;
        font-weight: bold;
        color: #0A2540;
        letter-spacing: 1px;
        position: relative;
        z-index: 2;

        &:hover {
          background: rgba(255, 255, 255, 0.4);
        }

        .icon_copyright {
          @include BgImage(18px, 18px, 'home/images/icon-copyright.png');
          vertical-align: middle;
          margin-right: 6px;
        }

        .icon_circle {
          margin-left: 48px;
          background: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          @include BgImage(18px, 18px, 'calendar/icon_add.png');
          position: relative;
          z-index: 10;
        }

        @include Screen(1) {
          font-size: 13px;
          width: 260px;
          .icon_circle {
            margin-left: 20px;
          }

        }
      }
    }
  }


  .menu-nav-list {
    display: flex;
    flex-wrap: wrap;
    height: 105px;
    overflow: hidden;
    //align-items: center;
    justify-content: center;
    position: relative;
    opacity: 0;
    padding-top: 24px;
    box-sizing: border-box;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 38px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #FFFFFF;
    }

    &.ON {
      opacity: 1;
    }

    .menu-nav-item {
      text-align: center;
      color: #0A2540;
      transition: all 0.5s ease-in-out;
      line-height: 40px;
      padding: 0 24px;
      margin: 0 14px 14px 0;
      flex-shrink: 0;
      height: 40px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(10, 37, 64, 0.1000);
      border-radius: 20px;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .on {
      color: #FFFFFF !important;
      background: $color-primary !important;
      background: #6969FF;
      box-shadow: 0px 2px 4px 0px rgba(10, 37, 64, 0.1000);
      border-radius: 20px;

      a {
        color: #FFFFFF !important;
      }

    }
  }

  /* .BannerNav-bg {
     @include BgImage(1440px, 151px, 'home/banner/BannerNav-bg%401440.svg');
     @include Position(1, 4, 50%, 0);
     transform: translateX(-50%);
   }*/

  @include Screen(1) {
    .banner-list {
      height: 277px;

      .img-wrap {
        margin-right: 12px;
        width: 294px;
        height: 277px;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          transition: all 0.5s ease-in-out;

        }
      }

      .calendar-card {
        padding: 11px 7px 8px;
        width: 276px;
        height: 258px;

        .calendar-card-item {
          height: 48px;
        }
      }
    }

    .calendar-wrap {


      .calendar-card {
        width: 222px;
        margin-right: 22px;

        .today,
        .tomorrow {
          width: 45px !important;
          height: 32px !important;
        }

        .today {
          line-height: 32px !important;
        }

        .card-top {
          height: 50px;
          padding: 0 16px;

          .card-lft {
            .calendar-name {
              font-size: 13px;
            }

            .calendar-date {
              font-size: 12px;
            }
          }

          .card-rgt {

          }
        }

        .card-bot {
          height: 84px;
        }
      }
    }

    .menu-nav-list {
      .menu-nav-item {
        padding: 0 24px;
      }
    }
  }
}
</style>
