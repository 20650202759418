<template>

  <div class="HomeTopicRecommendWrap">
    <div class="inner_center ">
      <div
        v-for="(item,index) in topicData" :key="index"
        class="HomeTopicRecommend ">
        <div class="title">{{ item.config.title }}</div>
        <div class="words">{{ item.config.sub_title }}</div>
        <div class="list">
          <div
            class="list-item" v-for="vo in item.list" :key="vo.id"
            @click="picItemTopicClick(item), topicPicClick(vo)"
            @mouseover="topicMouseover(item)"
            @mouseleave="topicMouseleave(item)"
          >
            <div class="list-item-content">
              <!--              <img
                              v-lazy=" PAGE_PATH.BASE_IMG_URL + vo.image_url + '!/fw/452/quality/90/unsharp/true/compress/true'"
                              slot="content"
                              :alt="vo.title"
                              :title="vo.title"
                              class="pic-img"
                              @contextmenu.prevent=""
                            >-->
              <CustomImage
                :src="PAGE_PATH.BASE_IMG_URL + vo.image_url + '!/fw/452/quality/90/unsharp/true/compress/true'"
                :alt="vo.title"/>
              <a :href="vo.url" target="_blank"></a>
              <div class="bar">
                <div class="bar-title">{{ vo.title }}</div>
                <div class="bar-num">{{ vo.pic_num }}张</div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="more-topic" @click="showMoreTopic">查看更多专题</div>
    </div>
  </div>


</template>

<script>
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {fnTopic} from "@/fn";
import {TOPIC} from "@/constant/tupian/TOPIC";
import CustomImage from "@/components/_Public/CustomImage"


export default {
  name: "HomeTopicRecommend",
  components: {
    CustomImage
  },
  props: {
    topicData: {
      type: Array | Object,
      default: () => []
    },
    topicDataMore: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      PAGE_PATH
    }
  },
  methods: {
    showMoreTopic() {
      this.$emit('showMoreTopic')
    },
    picItemTopicClick(picItemInfo) {
      this.$emit('picItemTopicClick', picItemInfo.id);
    },

    topicMouseover(picItemInfo) {
      this.$emit('topicMouseover', picItemInfo.id);
    },
    topicMouseleave(picItemInfo) {
      fnTopic.closePositionStatMouseLeave();
    },

    topicPicClick(topicPic) {
      this.$emit('topicPicClick', topicPic);
    }
  }
}
</script>

<style scoped lang="scss">

.HomeTopicRecommendWrap {
  background: linear-gradient(180deg, #F5F6FA 0%, #FBFBFD 100%);
  padding: 40px 0 80px;
}

.list-item-content {
  position: relative !important;
  z-index: 10;
  background: #FFFFFF;
}


.HomeTopicRecommend {
  //margin-bottom: 48px;
  margin-bottom: 10px;

  @include Screen(1) {
    margin-bottom: 15px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    color: #2D2B33;
    line-height: 43px;
    letter-spacing: 1px;
    margin-bottom: 14px;
  }

  .words {
    margin-bottom: 42px;
    font-size: 18px;
    color: rgba(71, 83, 85, 0.9);
    line-height: 20px;

    a {
      font-size: 18px;
      color: rgba(0, 16, 19, 0.8);
      line-height: 20px;
      margin-right: 10px;
    }
  }

  .list {
    .list-item {
      display: inline-block;
      width: 264px;
      height: 344px;
      //background: #CCCCCC;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
      border-radius: 14px;
      margin: 0 30px 30px 0;
      position: relative;
      transition: all 0.5s ease-in-out;
      position: relative;

      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 5;
        border-radius: 8px;
        transition: all 0.5s ease;

      }

      .list-item-content {
        display: inline-block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 14px;


        .bar {
          width: 100%;
          height: 90px;
          background: linear-gradient(180deg, rgba(0, 0, 26, 0) 0%, rgba(0, 0, 26, 0.6) 100%);
          border-radius: 0px 0px 14px 14px;
          @include Position(1, 4, 0, 0);
          z-index: 5;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 46px 18px 0;

          @include Screen(1) {
            padding: 50px 18px 0;
          }

          .bar-title {
            font-size: 20px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 27px;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
          }

          .bar-num {
            font-size: 14px;
            color: #FFFFFF;
            line-height: 19px;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            padding-top: 3px;
          }
        }

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 6;
        }
      }

      &:after {
        content: '';
        @include BgImage(264px, 34px, 'home/list-item%40big2.png');
        @include Position(1, 5, 0, -18px);
      }

      &:nth-child(5n) {
        margin-right: 0;
      }

      @include Screen(1) {
        width: 220px;
        height: 288px;
        margin: 0 25px 25px 0;
        &:after {
          content: '';
          @include BgImage(220px, 34px, 'home/list-item%40small2.png');
        }

        .list-item-content {
          .bar {
            .bar-title {
              font-size: 18px;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 24px;
              text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            }

            .bar-num {
              font-size: 14px;
              color: #FFFFFF;
              line-height: 19px;
              text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
      @include ElementHover('', '') {
        .pic-img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.more-topic {
  width: 966px;
  height: 56px;
  background: linear-gradient(44deg, #FE7E7E 0%, #F94D67 100%);
  box-shadow: 0px 2px 4px 0px rgba(10, 37, 64, 0.1);
  border-radius: 14px;
  font-size: 17px;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3px;
  text-align: center;
  line-height: 56px;
  margin: 0 auto;

  @include ElementHover('', linear-gradient(44deg, #F94D67 0%, #FE7E7E 100%));
}
</style>
