import request from "@/utils/request";
import qs from "qs";


/* 图片包列表 */
export function getPicPackageByGuoqing(data) {
  return request({
    url: '/?m=DoubleFestival&a=package',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 优惠券列表 */
export function getCouponListByGuoqing(query) {
  return request({
    url: '/?m=DoubleFestival&a=coupon',
    method: 'get',
    params: query
  })
}

/* 领取优惠券 */
export function receiveCouponByGuoqing(query) {
  return request({
    url: '/?m=DoubleFestival&a=couponGet',
    method: 'get',
    params: query
  })
}

/* 拉新绑定 */
export function checkInviteByGuoqing(data) {
  return request({
    url: '/?m=DoubleFestival&a=invite',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 拉新状态 */
export function getInviteStatusByGuoqing(query) {
  return request({
    url: '/?m=DoubleFestival&a=inviteStatus',
    method: 'get',
    params: query
  })
}


/* 获取Vip剩余次数 */
export function getRemainNumByGuoqing(query) {
  return request({
    url: '/?m=DoubleFestival&a=actVip',
    method: 'get',
    params: query
  })
}

/* 活动支付 */
export function payByGuoqing(data) {
  return request({
    url: '/?m=DoubleFestival&a=actPay',
    method: 'post',
    data: qs.stringify(data)
  })
}

/* 单张购买2 */
export function getSinglePageActByGuoqing(query) {
  return request({
    url: '/?m=Picture&a=singlePageAct',
    method: 'get',
    params: query
  })
}
